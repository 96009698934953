@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&family=Red+Hat+Text&display=swap');
@import '~@fortawesome/fontawesome-free/css/fontawesome.min.css';
@import '~@fortawesome/fontawesome-free/css/brands.min.css';
@import '~@fortawesome/fontawesome-free/css/regular.min.css';
@import '~@fortawesome/fontawesome-free/css/solid.min.css';
@import '~unsemantic/assets/stylesheets/unsemantic-grid-responsive.css';

@import 'stylesheets/chem-elements.css';
@import 'stylesheets/footer.css';
@import 'stylesheets/header.css';
@import 'stylesheets/identities.css';
@import 'stylesheets/intro.css';
@import 'stylesheets/labels.css';

body {
    background: url(./assets/images/background.webp) #272822;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;

    font-size: 1em;
    margin: 0;
    min-height: 100vh;
    padding: 5vh 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

html {
    /*
    font-family: 'Helvetica Neue', Helvetica, Arial, PingFangTC-Light, 'Microsoft YaHei', 微软雅黑, 'STHeiti Light',
        STXihei, '华文细黑', Heiti, 黑体, sans-serif;
    */
    font-family: 'Red Hat Display', 'Helvetica Neue', Helvetica, Arial, 'UD Digi Kyokasho NK-R', 'YuKyokasho Yoko', sans-serif;
    margin: 0;
    padding: 0;
}

#container {
    background: linear-gradient(to right, #ee0000, #820000) #be0000;
    background-size: cover;
    box-shadow: 0 0 0.15em 0.15em rgba(0, 0, 0, 0.125);
    /* padding: 1em 0; */
    margin: 0 auto;
    max-width: 64em;
}

.no-wrap {
    display: inline-block;
}

/*
#container,
#container > *:first-child {
    border-top-left-radius: 2em;
}

#container,
#container > *:last-child,
#container > *:last-child > *:last-child {
    border-bottom-right-radius: 2em;
}
*/

.header .small-labels .group::before,
.header .small-labels .label::before {
    color: #eee;
}

.identities .small-labels .group::before,
.identities .small-labels .label::before {
    color: #333;
}

@media (max-width: 768px) {
    body {
        padding: 0;
    }

    #container {
        padding: 0;
    }

    #container,
    #container>*:first-child {
        border-top-left-radius: 0;
    }

    #container,
    #container>*:last-child,
    #container>*:last-child>*:last-child {
        border-bottom-right-radius: 0;
    }

    .section.header {
        background: rgba(0, 0, 0, 0.75);
    }

    .intro .block .split {
        padding: 0 0.5em;
    }

    .intro p {
        border-left: none;
        padding: 0.2em;
    }
}